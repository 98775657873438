<template>
  <!--<<<<<<< HEAD-->
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h3>ແຜນທີ່ສະແດງລູກຄ້າ</h3>
<!--            <h3>{{markerss}}</h3>-->
<!--            <h3>{{counts}}</h3>-->
          </v-col>
<!--          <v-col>-->
<!--            <v-autocomplete-->
<!--                dense-->
<!--                outlined-->
<!--                required-->
<!--                v-model="selectedVillages"-->
<!--                :items="listVillages"-->
<!--                item-text="name"-->
<!--                item-value="id"-->
<!--                label="ບ້ານ"-->
<!--                multiple-->
<!--            >-->
<!--            </v-autocomplete>-->
<!--          </v-col>-->
        </v-row>
        <!--=======-->
        <v-container>
          <v-row>
            <v-col cols="12" class="mb-4">
              <GmapMap
                  v-if="customers"
                  :center="getCenter()"
                  :zoom="12"
                  style="width: 100%; height: 900px"
                  :disableDefaultUI="true"
              >
                <gmap-info-window
                    :options="infoOptions"
                    :position="infoPosition"
                    :opened="infoOpened"
                    :conent="infoContent"
                    @closeclick="infoOpened = false"
                >{{ infoContent }}
                </gmap-info-window>

<!--                <gmap-info-window-->
<!--                    @closeclick="infoOpened=false"-->
<!--                    :opened="infoOpened"-->
<!--                    :position="infoPosition"-->
<!--                    :options="infoOptions"-->
<!--                >-->
<!--                </gmap-info-window>-->
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markerss"
                    :position="m.position"
                    :draggable="false"
                    :animation="2"
                    :clickable="true"
                    :icon="m.icon"
                    @click="openWindow(m, index)"
                    style="width: 15px;height: 15px;"
                    class="icon"
                />
              </GmapMap>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      customers: [],
      districts: [],
      routePlan: {},

      //Map
      latlng: {
        lat: 18.1189434,
        lng: 102.290218,
      },

      markers: [],
      currentMidx:null,
      currentPlace: null,
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      counts:{
        // url: require('@coms/../../src/assets/vari_pin.png'),
        url: ('http://127.0.0.1:8000/images/icons/2989339baf3646093b6fe35a9710877e1666862161green-dot.png'),
        size: {
          width: 35,
          height: 55,
          f: 'px',
          b: 'px',
        },
        scaledSize: {
          width: 35,
          height: 55,
          f: 'px',
          b: 'px',
        },
      }
    }
  },
  methods: {
    backPrevios() {
      this.$router.go(-1);
    },
    createPlan() {
      if (this.selectedRow.length > 0) {
        this.$router.push({
          name: "CreateRoutePlanCompany",
          params: {
            items: this.selectedRow,
            // items: this.customers,
          },
        });
      } else {
        this.$store.commit("Toast_State", {
          value: true,
          color: "error",
          msg: "ກາລຸນາເລືອກບ້ານ ແລະ ລູກຄ້າກ່ອນ",
        });
      }
      // this.$router.push({name: 'CreatePlan'});
    },
    AddModal() {
      this.$store.commit("modalAdd_State", true);
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.Submit();
      }
    },

    async fetchData() {
      this.TableLoading = true;
      this.$admin.get('route-plan/map').then(res => {
        this.customers = res.data.data;
        this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
      });
    },

    // setIcons() {
    //   // console.log(m.plan);
    //   // if(m.plan.icon) {
    //     const pin = {
    //       // eslint-disable-next-line global-require
    //       //  url: m.icon,
    //       //  url: require('../../../../src/assets/vari_pin.png'),
    //       // url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
    //       url: require('@coms/../../src/assets/vari_pin.png'),
    //       // url: require('@coms/../../src/assets/vari_pin.png'),
    //       // url: '#eee',
    //       size: {
    //         width: 35,
    //         height: 55,
    //         f: 'px',
    //         b: 'px',
    //       },
    //       scaledSize: {
    //         width: 35,
    //         height: 55,
    //         f: 'px',
    //         b: 'px',
    //       },
    //     }
    //     return pin;
    //   // } else {
    //   //   const pin2 = {
    //   //      url: require('../../../../src/assets/vari_pin.png'),
    //   //     // url: '#eee',
    //   //     size: {
    //   //       width: 35,
    //   //       height: 55,
    //   //       f: 'px',
    //   //       b: 'px',
    //   //     },
    //   //     scaledSize: {
    //   //       width: 35,
    //   //       height: 55,
    //   //       f: 'px',
    //   //       b: 'px',
    //   //     },
    //   //   }
    //   //   return pin2;
    //   // }
    //
    // },
    //Google map
    getCenter() {
      if (this.customers.length) {
        if (parseFloat(this.customers[0].latitude) == null) {
          return this.latlng;
        } else {
          const latlng = {
            lat: parseFloat(this.customers[0].latitude),
            lng: parseFloat(this.customers[0].longitude),
          };
          return latlng;
        }
      }
      return this.latlng;
    },
    getMarkers(m) {
      if (m.latitude !== null) {
        return {
          lat: parseFloat(m.latitude),
          lng: parseFloat(m.longitude),
        };
      }
    },
    toggleInfo(m, key) {
      this.infoPosition = this.getMarkers(m);
      this.infoContent = `(${m.priority}) ${m.customer_code} ${m.name} ${m.phone} (${m.plan.name})`;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },

    getPosition: function(marker) {
      return {
        lat: parseFloat(marker.position.lat),
        lng: parseFloat(marker.position.lng)
      }
    },

    openWindow(marker, idx) {
      console.log(marker);

      //this.window_open = true;

      this.infoPosition = this.getPosition(marker);
      this.infoContent = `(${marker.priority}) ${marker.customer_code} ${marker.name} ${marker.phone} (${marker.routePlanName})`;

      // this.infoContent = '<div id="iw" class="iw-container">'+
      //     // '<div class="iw-prev mb-2">'+
      //     // '<img src="' + marker.icon +'" alt="">'+
      //     // '</div>'+
      //     '<div class="iw-body">'+
      //     '<div class="iw-label">$'+
      //     marker.label.text +
      //     '</div>'+
      //     '<h4 class="iw-address">'+
      //     marker.name +
      //     '</h4>'+
      //     '</div>'+
      //     '</div>';

      if (this.currentMidx === idx) {
        this.infoOpened = !this.infoOpened;
      }
      else {
        this.infoOpened = true;
        this.currentMidx = idx;
      }
    }

    // toggle() {
    //     this.$nextTick(() => {
    //         if (this.selectedAllVillage) {
    //             this.selectedVillage = [];
    //         } else {
    //             setTimeout(() => {
    //                 this.selectedVillage = this.villages.slice();
    //             }, 300);
    //         }
    //     });
    // },

  },
  watch: {
  },
  computed: {
    // marker() {
    //   return this.customers[0].customers.map(
    //       ({ name, location: { latitude, longitude } }) => ({
    //         name,
    //         position: {
    //           latitude,
    //           longitude: longitude
    //         }
    //       })
    //   );
    // }
    setIcons() {

       //  const icons =  this.customers.map(() => ({
       // }));

      const pin = {
        // url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        // url: require('@coms/../../src/assets/vari_pin.png'),
        url: ('http://127.0.0.1:8000/images/icons/2989339baf3646093b6fe35a9710877e1666862161green-dot.png'),
        // url: (icons),
        size: {
          width: 35,
          height: 55,
          f: 'px',
          b: 'px',
        },
        scaledSize: {
          width: 35,
          height: 55,
          f: 'px',
          b: 'px',
        },
      }
      // console.log(pin)
      return pin;

        // return this.customers.map(() => ({
        //    // icon:icon,
        //
        //    // url: require('@coms/../../src/assets/vari_pin.png'),
        //    url: ('http://127.0.0.1:8000/images/icons/2989339baf3646093b6fe35a9710877e1666862161green-dot.png'),
        //    // url: require(icon),
        //    size: {
        //      width: 35,
        //      height: 55,
        //      f: 'px',
        //      b: 'px',
        //    },
        //    scaledSize: {
        //      width: 35,
        //      height: 55,
        //      f: 'px',
        //      b: 'px',
        //    },
        //  }));
      },


    markerss() {
      return this.customers.map(({ id, latitude, longitude, name, priority,customer_code,routePlanName,phone,icon }) => ({
        label: {
          text: id,
          color: '#333',
          fontWeight: 'bold',
          fontSize: '30px'
        },
        position: {
          lat:latitude,
          lng: longitude
        },
        name,
        priority,
        customer_code,
        routePlanName,
        phone,
        icon:icon,
        // icon:{
        //   icon: icon,
        //   size: {
        //     width: 35,
        //     height: 55,
        //     f: 'px',
        //     b: 'px',
        //   },
        //   scaledSize: {
        //     width: 35,
        //     height: 55,
        //     f: 'px',
        //     b: 'px',
        //   },
        // }
        // icon: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#28a745" width="44" height="44" viewBox="0 0 24 24">' +
        //     '<path d="M12 3c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z"/>' +
        //     '</svg>'
      }));
    },
    // counts(){
    //   // this.customers.map((customer) => (customer))
    //   // for(let item in this.customers) {
    //   //   if(item.plan){
    //   //     console.log(item.plan);
    //   //     return item.plan.icon;
    //   //   } else {
    //   //     return null;
    //   //   }
    //   //
    //   // }
    //
    //   return this.customers.map((item) => item.icon);
    // },


    setIconOption(m) {
      if(m.plan.icon) {
      const pin = {
        // eslint-disable-next-line global-require
        //  url: m.plan.icon,
        //  url: require('../../../../src/assets/vari_pin.png'),
        url: `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`,
        // url: require('@coms/../../src/assets/vari_pin.png'),
        // url: '#eee',
        size: {
          width: 35,
          height: 55,
          f: 'px',
          b: 'px',
        },
        scaledSize: {
          width: 35,
          height: 55,
          f: 'px',
          b: 'px',
        },
      }
      return pin;

      } else {
        const pin2 = {
           url: require('../../../../src/assets/vari_pin.png'),
          // url: '#eee',
          size: {
            width: 35,
            height: 55,
            f: 'px',
            b: 'px',
          },
          scaledSize: {
            width: 35,
            height: 55,
            f: 'px',
            b: 'px',
          },
        }
        return pin2;
      }

    },

  },
  created() {
    this.fetchData();
  }
}
</script>

<style lang="scss">
@import "../../../../public/scss/main.scss";

.page--table {
  .page {
    &__table {
      margin-top: 20px;
    }

    &__grab-icon {
      cursor: move;
    }
  }
}
.icon{
  size {
    width: 35px;
    height: 55px;
  }
scaledSize{
  width: 35px;
  height: 55px;
}
}
</style>